class BaseLifeCycle {
  constructor(isSingleEvent) {
    this.singleEvent = isSingleEvent == void 0 ? true : isSingleEvent;
    this.onEvent = this.onEvent.bind(this);
    this.register = this.register.bind(this);
    this.unRegister = this.unRegister.bind(this);
    this.event = null;
    this.callbacks = [];
  }
  register(callback2) {
    if (typeof callback2 != "function") {
      console.warn(
        callback2 + " is not a function on " + this.constructor.name + ".register"
      );
      return;
    }
    if (this.event !== null && this.singleEvent) {
      callback2(this.event);
    }
    return this.callbacks.push({
      isActive: true,
      callback: callback2
    });
  }
  unRegister(id) {
    if (this.callbacks[id] == void 0) {
      console.warn(
        callback + " id does not exists on " + this.constructor.name + ".unregister"
      );
      return;
    }
    this.callbacks[id].isActive = false;
  }
  onEvent(event) {
    this.callCallbacks(event);
    this.event = event;
  }
  callCallbacks(event) {
    for (let i = 0; i < this.callbacks.length; i++) {
      if (this.callbacks[i].isActive) {
        this.callbacks[i].callback(event);
      }
    }
  }
}
class OnReady extends BaseLifeCycle {
  constructor() {
    super(true);
    if (document.readyState === "complete" || document.readyState === "loaded") {
      this.onEvent();
    } else {
      window.addEventListener("DOMContentLoaded", this.onEvent);
    }
  }
}
const onReady = new OnReady();
class OnLoad extends BaseLifeCycle {
  constructor() {
    super(true);
    window.onload = this.onEvent;
  }
}
const onLoad = new OnLoad();
function debounce(fn, delay, immediate = false) {
  let timerId;
  return (...args) => {
    const boundFunc = fn.bind(this, ...args);
    clearTimeout(timerId);
    if (immediate && !timerId) {
      boundFunc();
    }
    const calleeFunc = immediate ? () => {
      timerId = null;
    } : boundFunc;
    timerId = setTimeout(calleeFunc, delay);
  };
}
class OnResize extends BaseLifeCycle {
  constructor() {
    super(true);
    this.checkBody = this.checkBody.bind(this);
    this.bodyHeight = 0;
    this.bodyWidth = 0;
    window.addEventListener("resize", debounce(this.onEvent, 200));
    setTimeout(this.checkBody, 200);
  }
  checkBody() {
    let bodyHeight = document.body.offsetHeight;
    let bodyWidth = document.body.offsetWidth;
    if (bodyHeight !== this.bodyHeight || bodyWidth !== this.bodyWidth) {
      this.onEvent();
    }
    this.bodyHeight = bodyHeight;
    this.bodyWidth = bodyWidth;
    debounce(this.checkBody, 200);
  }
}
const onResize = new OnResize();
class OnScroll extends BaseLifeCycle {
  constructor() {
    super(true);
    this.elScrollContent = document.querySelector("[data-scroll-content]");
    this.elScrollContent.addEventListener("scroll", this.onEvent);
  }
}
const onScroll = new OnScroll();
class OnUpdate extends BaseLifeCycle {
  constructor() {
    super(false);
    this.date = /* @__PURE__ */ new Date();
    this.lastDate = this.date.getTime();
    this.onEvent();
  }
  onEvent() {
    this.date = /* @__PURE__ */ new Date();
    let currentDate = this.date.getTime();
    let delta = currentDate - this.lastDate;
    this.callCallbacks(delta > 50 ? 16 : delta);
    this.lastDate = currentDate;
    requestAnimationFrame(this.onEvent);
  }
}
const onUpdate = new OnUpdate();
class Component {
  constructor(el, args) {
    this._onReady = this._onReady.bind(this);
    this._onLoad = this._onLoad.bind(this);
    this._onResize = this._onResize.bind(this);
    this._onUpdate = this._onUpdate.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this.isInit = false;
    this.isReady = false;
    this.isActive = true;
    this.isLastActive = null;
    this.idReady = null;
    this.idLoad = null;
    this.idResize = null;
    this.idScroll = null;
    this.idUpdate = null;
    this._onInit(el, args);
    this.idReady = onReady.register(this._onReady) - 1;
    this.idLoad = onLoad.register(this._onLoad) - 1;
    this.idResize = onResize.register(this._onResize) - 1;
    this.idScroll = onScroll.register(this._onScroll) - 1;
    if (this.onUpdate != void 0) {
      this.idUpdate = onUpdate.register(this._onUpdate) - 1;
    }
  }
  _onInit(el, args) {
    this.isInit = true;
    this.onInit(el, args);
  }
  _onReady() {
    this.isReady = true;
    if (this.onReady != void 0) {
      this.onReady();
    }
  }
  _onLoad() {
    if (this.onLoad != void 0) {
      this.onLoad();
    }
  }
  _onResize() {
    if (this.onResize != void 0) {
      this.onResize();
    }
  }
  _onUpdate(delta) {
    if (this.isActive && this.isInit) {
      this.onUpdate(delta);
    }
    if (this.isLastActive !== null && this.isLastActive !== this.isActive) {
      if (this.isActive) {
        this._onActivate();
      } else {
        this._onDesactivate();
      }
    }
    this.isLastActive = this.isActive;
  }
  _onActivate() {
    if (this.onActivate != void 0) {
      this.onActivate();
    }
  }
  _onDesactivate() {
    if (this.onDesactivate != void 0) {
      this.onDesactivate();
    }
  }
  _onScroll() {
    if (this.onScroll != void 0) {
      this.onScroll();
    }
  }
  onDestroy() {
    if (this.idReady !== null) {
      onReady.unRegister(this.idReady);
    }
    if (this.idLoad !== null) {
      onLoad.unRegister(this.idLoad);
    }
    if (this.idResize !== null) {
      onResize.unRegister(this.idResize);
    }
    if (this.idScroll !== null) {
      onScroll.unRegister(this.idScroll);
    }
    if (this.idUpdate !== null) {
      onUpdate.unRegister(this.idUpdate);
    }
  }
}
export {
  Component as C,
  debounce as d
};
